import { useEffect, useRef, useState } from 'react';
import { Box, Slider, IconButton, Typography, useTheme, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PlayBoldSVG from '../../../assets/img/play-bold.svg';
import StopBoldSVG from '../../../assets/img/stop-bold.svg';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';
import * as _ from 'lodash';
import { getSoundPresignedUrl } from '../../../api/soundApi';
import { calculateOverallThresholdDb } from '../../../utils/tan-utils'

interface EqSimulateProps {
  tan: number[];
  overallThreshold: number;
}

const MAX_VALUE = 120;
const MIN_VALUE = 0;

const frequencyBand = [
  { frequency: 16, lowerLimit: 11, upperLimit: 22 },
  { frequency: 31.5, lowerLimit: 22, upperLimit: 44 },
  { frequency: 63, lowerLimit: 44, upperLimit: 88 },
  { frequency: 125, lowerLimit: 88, upperLimit: 177 },
  { frequency: 250, lowerLimit: 177, upperLimit: 355 },
  { frequency: 500, lowerLimit: 355, upperLimit: 710 },
  { frequency: 1000, lowerLimit: 710, upperLimit: 1420 },
  { frequency: 2000, lowerLimit: 1420, upperLimit: 2840 },
  { frequency: 4000, lowerLimit: 2840, upperLimit: 5680 },
  { frequency: 8000, lowerLimit: 5680, upperLimit: 11360 },
  { frequency: 16000, lowerLimit: 11360, upperLimit: 22720 },
];

function EqSimulate(props: EqSimulateProps) {
  const theme = useTheme();

  const [isPlay, setIsPlay] = useState(false);
  const [volumLevel, setVolumLevel] = useState(0);

  const [isAudioGenerating, setIsAudioGenerating] = useState(false);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [whiteNoiseUrl, setWhiteNoiseUrl] = useState<string | null>(null);
  const ffmpegRef = useRef(new FFmpeg());
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    setIsPlay(false);
    handleVolumChange(0);

    return () => {
      setIsPlay(false);
      handleVolumChange(0);

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    document.onkeydown = handleOnKeyDown;
    return () => {
      document.onkeydown = null;
    };
  }, [volumLevel]);

  const handlePlayOnClick = async () => {
    if (audioRef.current) {
      if (isPlay) {
        setIsPlay(false);

        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        setIsPlay(true);

        await generateEqAudio();

        audioRef.current.play();
      }
    }
  };

  const ffmpegLoad = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('log', ({ message }) => {
      console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    });
    setFfmpegLoaded(true);
  };

  const generateEqAudio = async () => {
    setIsAudioGenerating(true);

    // load ffmpeg
    if (!ffmpegLoaded) {
      await ffmpegLoad();
    }

    // get whiet noise file url
    let audioURL = whiteNoiseUrl;
    if (!audioURL) {
      const res = await getSoundPresignedUrl(process.env.REACT_APP_WHITE_NOISE_FILE_NAME ?? 'whitenoise.wav');
      setWhiteNoiseUrl(res.data.url);
      audioURL = res.data.url;
    }

    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile('input.wav', await fetchFile(audioURL as string));

    const tan = [
      calculateOverallThresholdDb(props.overallThreshold),
      calculateOverallThresholdDb(props.overallThreshold),
      calculateOverallThresholdDb(props.overallThreshold),
      calculateOverallThresholdDb(props.overallThreshold),
      calculateOverallThresholdDb(props.overallThreshold),
      props.tan[0],
      props.tan[1],
      props.tan[2],
      props.tan[3],
      props.tan[4],
      props.tan[5],
    ];

    const normalizedTanArr: number[] = [];

    let eqCmd = '';
    frequencyBand.forEach((band, index) => {
      // convert tan values into -12 ~ 12 range
      const normalizedTan = _.round(((tan[index] - -90) / 100) * 24 - 12, 2); // -12 ~ 12
      normalizedTanArr.push(normalizedTan);

      eqCmd += `equalizer=f=${band.frequency / 1000}:width_type=k:w=${
        (band.upperLimit - band.lowerLimit) / 1000
      }:g=${normalizedTan}`;

      if (frequencyBand.length - 1 !== index) {
        eqCmd += ',';
      }
    });

    //TODO: remove after QA completed
    console.log(eqCmd);
    console.log('tanDB', tan);
    console.log('normalizedTanArr', normalizedTanArr);

    await ffmpeg.exec([
      '-i',
      'input.wav',
      '-af',
      `loudnorm,${eqCmd}`,
      '-c:a',
      'pcm_s16le',
      '-ar',
      '44100',
      '-ac',
      '2',
      'output.wav',
    ]);

    const fileData = await ffmpeg.readFile('output.wav');

    const data = new Uint8Array(fileData as ArrayBuffer);
    if (audioRef.current) {
      audioRef.current.src = URL.createObjectURL(new Blob([data.buffer], { type: 'audio/wav' }));
    }

    setIsAudioGenerating(false);
  };

  const handleVolumChange = (value: number) => {
    if (audioRef.current) {
      setVolumLevel(value);
      audioRef.current.volume = value / 120;
    }
  };

  const handleVolumeIncrease = () => {
    if (volumLevel < 120) {
      handleVolumChange(volumLevel + 1);
    }
  };

  const handleVolumeDecrease = () => {
    if (volumLevel > 0) {
      handleVolumChange(volumLevel - 1);
    }
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    handleVolumChange(newValue as number);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    const keyPressValueMap = new Map<string, number>([
      ['ArrowUp', 1],
      ['ArrowDown', -1],
      ['+', 5],
      ['-', -5],
    ]);

    const pressKey = event.key;
    if (keyPressValueMap.has(pressKey)) {
      const value = volumLevel + Number(keyPressValueMap.get(pressKey));
      if (value > MAX_VALUE) {
        handleVolumChange(MAX_VALUE);
      } else if (value < MIN_VALUE) {
        handleVolumChange(MIN_VALUE);
      } else {
        handleVolumChange(value);
      }
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <VolumeUpIcon color="primary" />
            <Slider value={volumLevel} onChange={handleSliderChange} sx={{ marginX: 4 }} step={1} min={0} max={120} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={theme.palette.white.main}
              width={140}
              sx={{ borderWidth: '0.5px', borderStyle: 'solid', borderRadius: '10px' }}
            >
              <IconButton size="small" onClick={handleVolumeDecrease}>
                <RemoveCircleOutlineIcon color="primary" />
              </IconButton>
              <Typography>{volumLevel}</Typography>
              <IconButton size="small" onClick={handleVolumeIncrease}>
                <ControlPointIcon color="primary" />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <audio ref={audioRef} autoPlay={false} controls loop hidden></audio>

          <LoadingButton
            loading={isAudioGenerating}
            disabled={isAudioGenerating}
            sx={{ borderRadius: '10px' }}
            onClick={handlePlayOnClick}
            endIcon={<Box width={24} height={24} component="img" src={isPlay ? StopBoldSVG : PlayBoldSVG} />}
          >
            {isPlay ? 'Stop Demonstrate' : 'Demonstrate'}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}

export default EqSimulate;
